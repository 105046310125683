export class ActionComponent {
    private showAction: boolean;
    private actionBlock: HTMLElement;
    private closeButton: Element;
    private linkElement: Element;

    constructor() {
        this.showAction = !this.getCookie("_action");
        this.actionBlock = document.getElementById("offermodal");
        if (this.showAction && this.actionBlock) {
            this.actionBlock.classList.remove("hidden");

            this.closeButton = document.querySelector('.js-action-close');
            if (this.closeButton) {
                this.closeButton.addEventListener('click', () => {
                    this.actionBlock.classList.add("hidden");
                    this.setCookie("_action");
                });
            }

            this.linkElement = document.querySelector('.js-action-link');
            if (this.linkElement) {
                this.linkElement.addEventListener('click', () => {
                    this.setCookie("_action");
                })
            }
        }
    }

    private getCookie(key: string) {
        if (!key) {
            return null;
        }

        return (decodeURIComponent(document.cookie.replace
            (new RegExp("(?:(?:^|.*;)\\s*" +
                    encodeURIComponent(key).replace(/[\-\.\+\*]/g, "\\$&") +
                    "\\s*\\=\\s*([^;]*).*$)|^.*$"),
                "$1")) || null
        );
    }

    private setCookie(key: string) {
        document.cookie = encodeURIComponent(key) + '=' + encodeURIComponent("1") + '; path=/; Secure';
    }
}

